.rhap_main.rhap_stacked {
  display: flex;
  gap: 1rem;
}

.rhap_progress-section {
  display: none;
}

.rhap_controls-section {
  .rhap_additional-controls,
  .rhap_volume-controls {
    display: none;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    height: 40px;
    width: 40px;
    color: inherit;
    font-size: 35px;
    color: #34d399; // emerald-400
    .play-container:hover & {
      color: #10b981; // emerald-500
      html.dark & {
        color: #6ee7b7; // emerald-300
      }
    }
  }
}
