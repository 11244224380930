:root {
  --transfer-speed: 5s;
  --point-size: 6px;
}

.connection {
  position: relative;
  height: var(--point-size);
  width: 100%;
}

.point {
  display: block;
  position: absolute;
  z-index: 100;
  height: var(--point-size);
  width: var(--point-size);
  border-radius: var(--point-size);
  background: white;
  .light & {
    background: black;
  }
  @media screen and (max-width: 450px) {
    transform: scale(0.5);
  }
  animation: transfer var(--transfer-speed) cubic-bezier(0.42, 0, 0.58, 1) infinite;

  animation-play-state: paused;
  &.active {
    will-change: left, opacity;
    animation-play-state: running;
  }
}

.point:nth-child(1) {
  animation-delay: 0s;
}

.point:nth-child(2) {
  animation-delay: 0.5s;
}

.point:nth-child(3) {
  animation-delay: 1.1s;
}

.point:nth-child(4) {
  animation-delay: 1.7s;
}

.point:nth-child(5) {
  animation-delay: 2.3s;
}

.point:nth-child(6) {
  animation-delay: 2.9s;
}

@keyframes transfer {
  0% {
    left: 0;
    opacity: 0;
    // transform: translateX(calc(100% + var(--node-size) / 2));
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
    // transform: translateX(-20px);
  }
}
