.robot {
  box-sizing: border-box;
  position: relative;
  width: 160px;
  height: 180px;
  bottom: 0;
  left: calc(50% - 80px);
}

.head {
  position: absolute;
  width: 160px;
  height: 100px;
  background-color: #ccc;
  border-radius: 6px;
  overflow: hidden;
  background: linear-gradient(to right, #ccc 0%, lighten(#ccc, 10%) 40%, lighten(#ccc, 10%) 60%, #ccc 100%);
  transform-origin: 50% 100%;
  animation: bob 8000ms ease-in-out alternate infinite -1000ms;
}

.eyes {
  position: absolute;
  top: calc(25% - 10px);
  right: 30px;
  left: 30px;
  height: 20px;
  animation: blink 10000ms linear forwards infinite;
}

.eyeball {
  position: absolute;
  width: 12px;
  height: 20px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  background-color: black;
}

.eyeball_left {
  left: 0;
  transition: transform 100ms ease-in-out;
}

.eyeball_right {
  right: 0;
  transition: transform 100ms ease-in-out;
}

.mouth {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 150px;
  height: 20px;
  background-color: black;
  overflow: hidden;
  border-radius: 5px;
  transition: height 100ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.mouth-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.robot_speaking {
  .mouth {
    height: 40px;
  }
  .mouth-container {
    animation: speakingAnim 0.3s infinite;
  }
}

.mouth-container-line {
  position: absolute;
  top: 50%;
  height: 2px;
  background-color: limegreen;
  width: 100%;
  margin-top: -1px;
}

.torso {
  position: absolute;
  bottom: 0;
  left: calc(50% - 40px);
  width: 80px;
  height: 60px;
  border-radius: 6px 6px 0 0;
  background: linear-gradient(to right, #ccc 0%, lighten(#ccc, 10%) 40%, lighten(#ccc, 10%) 60%, #ccc 100%);
}

.neck {
  position: absolute;
  bottom: 45px;
  left: calc(50% - 15px);
  width: 30px;
  height: 50px;
  border-radius: 15px;
  background: repeating-linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 7%, rgb(100, 100, 100) 10%),
    linear-gradient(to right, #ccc 0%, lighten(#ccc, 10%) 40%, lighten(#ccc, 10%) 60%, #ccc 100%);
}

.arms {
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  height: 50px;
}

.arm {
  position: absolute;
  top: 0;
  width: 20px;
  height: 50px;
  border-radius: 10px 10px 0 0;
  background: repeating-linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 7%, rgb(100, 100, 100) 10%),
    linear-gradient(to right, #ccc 0%, lighten(#ccc, 10%) 40%, lighten(#ccc, 10%) 60%, #ccc 100%);
}

.arm_left {
  left: 0;
}

.arm_right {
  right: 0;
}

@keyframes lowAnim {
  0% {
    filter: url('#low-0');
  }
  25% {
    filter: url('#low-1');
  }
  50% {
    filter: url('#low-2');
  }
  75% {
    filter: url('#low-3');
  }
  100% {
    filter: url('#low-4');
  }
}

@keyframes listeningAnim {
  0% {
    filter: url('#listening-0');
  }
  25% {
    filter: url('#listening-1');
  }
  50% {
    filter: url('#listening-2');
  }
  75% {
    filter: url('#listening-3');
  }
  100% {
    filter: url('#listening-4');
  }
}

@keyframes speakingAnim {
  0% {
    filter: url('#speaking-0');
  }
  25% {
    filter: url('#speaking-1');
  }
  50% {
    filter: url('#speaking-2');
  }
  75% {
    filter: url('#speaking-3');
  }
  100% {
    filter: url('#speaking-4');
  }
}

@keyframes bob {
  0% {
    transform: rotate(-3deg);
  }
  40% {
    transform: rotate(-3deg);
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
  }
  60% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(3deg);
  }
}

@keyframes blink {
  50% {
    transform: scale(1, 1);
  }
  51% {
    transform: scale(1, 0.1);
  }
  52% {
    transform: scale(1, 1);
  }
}
